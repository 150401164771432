import Proj1 from "../assets/proj1.jpg";
import Proj2 from "../assets/proj2.jpg";
import Proj3 from "../assets/proj3.jpg";
import Proj4 from "../assets/proj4.jpg";
import Proj5 from "../assets/proj5.jpg";
import Proj6 from "../assets/proj6.jpg";


export const ProjectList = [
  {
    name: "Expense Tracker",
    url: "https://github.com/hristijanristeski/JS-Projects/tree/master/Expense%20Tracker%20App",
    image: Proj1,
    skills: "React, JavaScript, HTML, CSS",
  },
  {
    name: "Add Goal",
    url: "https://github.com/hristijanristeski/JS-Projects/tree/master/Add%20Goal%20App",
    image: Proj2,
    skills: "React, JavaScript, HTML, CSS",
  },
  {
    name: "Dice Game",
    url: "https://github.com/hristijanristeski/JS-Projects/tree/master/Dice%20Game",
    image: Proj3,
    skills: "JavaScript, HTML, CSS",
  },
  {
    name: "Drum Kit",
    url: "https://github.com/hristijanristeski/JS-Projects/tree/master/Drum%20Kit%20App",
    image: Proj4,
    skills: "JavaScript, HTML, CSS",
  },
  {
    name: "Simon Game Challenge",
    url: "https://github.com/hristijanristeski/JS-Projects/tree/master/Simon%20Game%20Challenge",
    image: Proj5,
    skills: "JavaScript, HTML, CSS",
  },
  {
    name: "Food Order",
    url: "https://github.com/hristijanristeski/JS-Projects/tree/master/Food%20Order%20App",
    image: Proj6,
    skills: "React, JavaScript, HTML, CSS, Database",
  },
];
